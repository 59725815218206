<template>
  <div>
    <v-form v-model="isValid" v-on:submit.prevent="login" id="login-form">
      <v-container class="mt-4">
        <div class="d-flex align-center">
          <v-img
            alt="Золотое сечение"
            contain
            src="@/assets/splash-logo.png"
            max-height="315"
          />
        </div>
        <v-row justify="center" align="center">
          <v-col cols="10" sm="6" md="4" xl="3">
            <v-text-field
              v-model="loginData.login"
              :rules="[rules.required]"
              label="Логин"
              hint="Введите логин"
              color="#af955c"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="10" sm="6" md="4" xl="3">
            <v-text-field
              v-model="loginData.password"
              color="#af955c"
              :append-icon="isVisible ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :type="isVisible ? 'text' : 'password'"
              name="password"
              label="Пароль"
              hint="Введите пароль"
              required
              @click:append="isVisible = !isVisible"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-btn
            outlined
            large
            :disabled="!isValid"
            color="#af955c"
            type="submit" 
            form="login-form"
            >Войти</v-btn
          >
        </v-row>
        <v-row class="mt-4" justify="center" align="center">
          <div>
            <p class="text-button text-center" @click="remindPassword">
              Я не могу войти
            </p>
            <p class="text-button text-center" @click="registration">
              У меня нет аккаунта
            </p>
          </div>
        </v-row>
      </v-container>
    </v-form>
    <v-snackbar v-model="resultSnackbar" vertical multi-line timeout="-1">
      <span v-html="resultText"></span>
      <template v-slot:action="{ attrs }">
        <v-btn color="error" text v-bind="attrs" @click="() => {resultSnackbar = false; resultText = '';}">
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "LoginForm",
  data: () => ({
    loginData: {
      login: "",
      password: "",
    },

    isValid: false,
    isVisible: false,

    resultSnackbar: false,
    resultText: '',

    rules: {
      required: (value) => !!value || "Обязательное поле",
    },
  }),
  methods: {
    async login() {
      try {
        const currentUrl = new URL(window.location.href)
        const urlArgs =  new URLSearchParams(currentUrl.search)
        const returnUrl = urlArgs.get('ReturnUrl') || this.$route.query.ReturnUrl;
        let authData = new FormData();
        authData.append('login', this.loginData.login.trim());
        authData.append('password', this.loginData.password);
        authData.append('returnUrl', returnUrl)
        let response = await axios.post('/signin', authData ,{
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest'
          }
        });
        this.getAuthResponse(response, returnUrl ?  returnUrl.includes('orders') || returnUrl.includes('staff') || returnUrl.includes('quals') ?  '/#' + returnUrl :
            returnUrl :
          null);
      }
      catch(error) {
        let data = {
          success: false,
          message: error.message
        }
        let response = [data];
        this.getAuthResponse(response);
      }
    },
    getAuthResponse(response, returnUrl) {
      if(response.data && response.data.success === true){
        const currentUrl = new URL(window.location.href)
        const originUrl = currentUrl.origin
        if(returnUrl) {
          const redirectUrl = new URL(returnUrl, originUrl).href
          window.location.href = redirectUrl
        }
        else {
          window.location.reload()
        }
      }
      else if(response.request && response.request.responseURL && response.request.responseURL.includes('LicenseError')) {
        window["location"].href = response.request.responseURL;
      }
      else {
        this.loginData.password = null;
        this.resultSnackbar = true;
        this.resultText = 'Некорректный логин или пароль';
      }
    },
    registration() {
      this.$router.push("registration");
    },
    remindPassword() {
      window.location.href = '/recovery';
    },
  },
};
</script>

<style lang="scss">
.text-button {
  color: #af955c;
  cursor: pointer;
  line-height: 1.7rem !important;
  margin: 0 !important;

  &:hover {
    text-decoration: underline;
  }
}
</style>