<template>
  <v-container>
    <LoginForm />
  </v-container>
</template>

<script>

  import LoginForm from '../components/LoginForm';

  export default {
    name: 'SignIn',
    components: {
      LoginForm
    }
  }
</script>
